<template>
  <div v-show="showTop" class="back-top" @click.stop="backTop">
    <img alt="" src="/static/assets/img/cms/up@2x.png">
  </div>
</template>

<script>
export default {
  props: {
    eleId: ''
  },
  name: 'app',
  data() {
    return {
      showTop: false,
      scrollEle: null
    }
  },
  methods: {
    backTopSize(event) {
      let scrollTop = event.target?.scrollTop || document.documentElement.scrollTop
      this.showTop = scrollTop > 350;
    },
    backTop() {
      this.scrollEle?.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })
    }
  },
  mounted() {
    this.scrollEle = !!this.eleId ? document.getElementById(this.eleId) : window;
    this.$nextTick(() => {
      this.scrollEle?.addEventListener('scroll', this.backTopSize);
    })
  },
  unmounted() {
    this.scrollEle.addEventListener('scroll', this.backTopSize);
    this.scrollEle = null;
  }

}
</script>

<style lang="scss" scoped>
.back-top {
  position: fixed;
  right: 20px;
  bottom: 80px;
  z-index: 999;

  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: block;
    padding: 5px;
    background-color: #fff;
  }
}
</style>
