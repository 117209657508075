<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- 回到顶部 -->
    <BackTop/>
  </div>
</template>

<script>
import BackTop from "@/components/backtop/backtop.vue";

export default {
  components: {BackTop},
  mounted() {
    console.log(this.$route)
  }
}
</script>

<style lang="scss">
@import "/src/style/base.scss";
@import "static/assets/font/iconfont.css";

</style>
